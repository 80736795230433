<template>
	<v-container class="mt-4 px-5" fluid>
	  <v-row>
	  	<v-col cols="12">
	  		<v-card class="shadowCard">
	  			<v-card-title primary-title>
	  		    <span class="text-subtitle-1">Coordinación de sucursales Marketing</span>
            <v-spacer></v-spacer>
	  		    <v-btn
              color="primary"
              dark
              @click="initialize"
              small
              tile
            >
              Actualizar
            </v-btn>
            
	  		  </v-card-title>
          
          <v-card-text>

            <v-row>
              <v-col cols="12" sm="6" md="3">
                <v-text-field
                  label="Fecha"
                  dense
                  filled
                  v-model="fecha"
                  class="ms-4"
                  type="date"
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="6" md="4">
                <label>Elige el estatus de la huella</label>
                <v-radio-group
                  v-model="opHuella"
                  row
                  hide-details
                >
                  <v-radio
                    label="Con"
                    :value="1"
                  ></v-radio>
                  <v-radio
                    label="Sin"
                    :value="2"
                  ></v-radio>
                  <v-radio
                    label="Todas"
                    :value="3"
                  ></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>

  				  <v-data-table
  				    :headers="headers"
  				    :items="filterEmpleados"
  				    class="elevation-0"
              dense
              :items-per-page="empleados.length"
              hide-default-footer
  				  >

              <template v-slot:item.vendedoras_2="{ item }">
                <span
                  v-for="( vendedora, i ) in item.vendedoras_2" 
                  :key="i"
                >
                  <v-chip 
                    small
                    :color="vendedora.asistencia == 1 ? 'green' : 'red'" 
                    dark
                    v-if="( opHuella == 1 && vendedora.asistencia == 1 ) || ( opHuella == 2 && !vendedora.asistencia ) || opHuella == 3"
                  >
                      <v-icon left v-if="vendedora.asistencia == 1">mdi-circle-medium</v-icon>
                      <v-icon left v-if="vendedora.reemplazo == 1">mdi-skull</v-icon> 
                      {{ vendedora.nombre_usuario }} 
                  </v-chip>
                </span>
              </template>

  				    <template v-slot:no-data>
  				      <v-btn
  				        color="primary"
  				        @click="initialize"
  				        small
  				      >
  				        Actualizar
  				      </v-btn>
  				    </template>
  				  </v-data-table>
              
          </v-card-text>

				</v-card>
		  </v-col>
	  </v-row>

	  <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

	</v-container>
</template>
<script>
  import axios from 'axios';
	import { mapGetters } from 'vuex'
	// Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import validarErrores  from '@/mixins/validarErrores'
  import funcionesExcel  from '@/mixins/funcionesExcel'


  export default {

  	components:{
      Alerta,
      carga,
    },

    mixins: [ validarErrores, funcionesExcel ],

    data: () => ({
    	// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      reemplazoAux: 0,

      headers: [
        { text: 'Plantel',                     value: 'plantel',           },
        { text: 'Vendedora',                   value: 'vendedoras_2'       },
      ],

      empleados: [],

      fecha: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),

      opHuella: 3,
    }),

    watch: {
      dialog (val) {
        val || this.close()
      },
    },

    async created () {
      console.log( 'gohohpi',this.getdatosUsuario )
      await this.initialize()
    },

    computed: {
      ...mapGetters("login", ["getdatosUsuario", "getLogeado"]),

      filterEmpleados( ){

        let data = this.empleados

        return data
      }


    },

    methods: {
      initialize () {
        this.empleados = []
        this.cargar = true

        this.colaboradoresTotales = Object.assign({}, this.payload )
        this.$http.get('puestos.plantel').then(response=>{
          this.puestosTotales = response.data
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { })

        this.$http.get('trabajador.ultimocambio').then(response=>{
          this.ultimoCambio = response.data
          console.log(this.ultimoCambio)
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { })


        const payload = {
          dia: this.fecha
        }

        return this.$http.post('asistencias.visuales', payload ).then(response=>{

        	this.empleados      = response.data.plantelesActivos

        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      validarSuccess( mensaje ){
        this.cargar = false
        this.parametros.mensaje      = mensaje
        this.parametros.dialogError  = true 
        this.parametros.color        = 'success'
        this.respuestaAlerta         = true 
      },

    },
  }
  /*454*/
</script>